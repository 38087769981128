import './sass/app.scss';
import Echo from "laravel-echo";
import iziToast from "izitoast"
import Pusher from 'pusher-js';

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'clarus',
	wsHost: window.location.hostname,
    wssPort: window.location.port,
    wsPort: window.location.port,
	disableStats: true,
    forceTLS: true,
    encrypted: true,
    enabledTransports: ['ws', 'wss']
});

jQuery(document).ready(function () {
    let id = $("#user_id").data("id");
    window.Echo.private(`surveys.export.${id}`)
        .listen('ExportSurveys', (e) => {
            progressUpdate(e);
        });


    function progressUpdate(e) {
        let perc = (e.processed / e.total) * 100;
        perc = perc.toFixed(2);
        $('#export-info-' + e.exportId).text(e.message);
        $('#export-status-' + e.exportId).text(e.status);
        $('#export-bar-' + e.exportId).attr("aria-valuenow", perc);
        $('#export-bar-' + e.exportId).css("width", perc + "%");
        $('#export-bar-' + e.exportId).text(perc + "%");
        if(e.status == 'complete') {
            iziToast.show({
                title: 'Success!',
                message: 'Your file has been exported!',
                color: 'green',
                position: 'topRight'
            });
        } else if(e.status == 'error') {
            iziToast.show({
                title: 'Oh no!',
                message: 'The export failed!',
                color: 'red',
                position: 'topRight'
            });
        }
    }
})